import type { App } from 'vue'

// 需要全局引入一些组件，如ElScrollbar，不然一些下拉项样式有问题
import { ElLoading, ElScrollbar } from 'element-plus'

const plugins = [ElLoading]

const components = [ElScrollbar]

export const setupElementPlus = (app: App<Element>) => {
  plugins.forEach((plugin) => {
    app.use(plugin)
  })

  // 为了开发环境启动更快，一次性引入所有样式（这边因为会把top、left 等属性 转位 inset , inset 属性部分浏览器不支持）
  // if (import.meta.env.VITE_USE_ALL_ELEMENT_PLUS_STYLE === 'true') {
  //   import('element-plus/dist/index.css')
  //   return
  // }

  components.forEach((component) => {
    app.component(component.name!, component)
  })
}
