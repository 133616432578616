import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout, getParentLayout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'
import tenant from '@/store/tenant'



const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/sso-login',
    component: () => import('@/views/sso/login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
]

export const useBaseRouters = ():AppRouteRecordRaw[] => {

  const {
    portalHomeSettingFlag,
    portalMyMessageSettingFlag,
    portalMyTodoSettingFlag,
    portalReportCenterSettingFlag,
    reportCenterUrl,
    portalWorkbenchSettingFlag
  } = tenant.info;
  const protalList: AppRouteRecordRaw[] = [
    {
      path: "home",
      component: () => import('@/views/Dashboard/girdLayout/View.vue'),
      name: "GridLayoutView",
      hidden: !portalHomeSettingFlag,
      meta: {
        title: "首页",
        noCache: true,
        affix: true
      }
    },
    {
      path: 'workbench',
      component: () => import('@/views/Home/Index.vue'),
      name: 'PortalIndex',
      hidden: !portalWorkbenchSettingFlag,
      meta: {
        title: '工作台',
        noCache: true,
        affix: true
      }
    },
    {
      path: "approval",
      hidden: !portalMyTodoSettingFlag,
      component: () => import('@/views/Approval/index.vue'),
      name: "Approval",
      meta: {
        title: "任务中心",
        noCache: true,
      }
    },
    {
      path: "message",
      hidden: !portalMyMessageSettingFlag,
      component: () => import('@/views/notify/my/index.vue'),
      name: 'PortalMessage',
      meta: {
        title: '我的消息',
        noCache: true,
        affix: true
      }
    },
    {
      path: "announce",
      hidden: false,
      component: () => import('@/views/notify/announce/index.vue'),
      name: 'PortalAnnounce',
      meta: {
        title: '系统公告',
        noCache: true,
        affix: true
      }
    },
    {
      path: "import-center",
      component: () => import('@/views/ImportFileCenter/index.vue'),
      hidden: false,
      name: 'ImportFileCenter',
      meta: {
        title: '导入中心',
        noCache: true,
        affix: true
      }
    },
    // {
    //   path: "backlog",
    //   hidden: !portalMyTodoSettingFlag,
    //   component: () => import('@/views/notify/backlog/index.vue'),
    //   name: "BackLog",
    //   meta: {
    //     title: "我的待办",
    //     noCache: true,
    //     affix: true,
    //   }
    // },
    {
      path: "notice/detail",
      hidden: !portalMyTodoSettingFlag,
      component: () => import('@/views/notify/detail/index.vue'),
      name: "NotifyDetail",
      meta: {
        title: "新闻公告详情",
        noCache: true,
        affix: true,
      }
    },
    {
      path: "report",
      hidden: !portalReportCenterSettingFlag,
      component: reportCenterUrl? () => import('@/views/Application/Iframe.vue') : () => import('@/views/notify/report/index.vue'),
      name: 'PortalReportCenter',
      props: reportCenterUrl? { url: reportCenterUrl } : undefined,
      meta: {
        title: '我的报表',
        noCache: false,
        affix: true
      }
    },
    {
      path: "reportRender",
      component: () => import('@/views/notify/report/reportRender.vue'),
      name: 'ReportRender',
      meta: {
        title: '报表预览',
        noCache: true,
        affix: true
      }
    },
    {
      path: 'workbench',
      component: () => import('@/views/Home/Save.vue'),
      name: "workbench-setting",
      meta: {
        title: t("工作台编辑"),
        noCache: true,
        affix: true
      }
    }
  ].filter((o) => !o.hidden);


  /**
   * 获取重定向路径
   * @returns {string} 返回重定向的路径
   * - 如果租户配置了首页菜单(firstShowMenu)且该路径存在于门户列表中,则返回配置的路径
   * - 否则返回门户列表的第一个路径作为默认值
   */
  const getRedirectPath = () => {
    const path = tenant.info.firstShowMenu
    const exist = protalList.find(item => item.path === path)
    return exist ? path : protalList[0].path
  }

  const asyncRouterMap: AppRouteRecordRaw[] = [
    {
      path: '/',
      component: Layout,
      redirect: `/portal/${getRedirectPath()}`,
      name: 'Root',
      meta: {
        hidden: true
      }
    },
    {
      path: '/personal',
      component: Layout,
      redirect: '/personal/personal-center',
      name: 'Personal',
      meta: {
        title: t('router.personal'),
        hidden: true,
        canTo: true
      },
      children: [
        {
          path: 'personal-center',
          component: () => import('@/views/Personal/PersonalCenter/PersonalCenter.vue'),
          name: 'PersonalCenter',
          meta: {
            title: t('router.personalCenter'),
            hidden: true,
            canTo: true
          }
        }
      ]
    },
    {
      path: '/portal',
      component: Layout,
      redirect: `/portal/${protalList[0].path}`,
      name: 'PageIndex',
      meta: {
        title: t('router.dashboard'),
        icon: 'ant-design:dashboard-filled',
        alwaysShow: true
      },
      children: protalList,
    },
    {
      path: '/dashboard',
      component: Layout,
      redirect: '/dashboard/analysis',
      name: 'Dashboard',
      meta: {
        title: t('router.dashboard'),
        icon: 'ant-design:dashboard-filled',
        alwaysShow: true
      },
      children: [
        {
          path: 'analysis',
          component: () => import('@/views/Dashboard/Analysis.vue'),
          name: 'Analysis',
          meta: {
            title: t('router.analysis'),
            noCache: true,
            affix: true
          }
        },
        {
          path: 'girdlayout',
          component: () => import("@/views/Dashboard/girdLayout/index.vue"),
          name: "girdlayout",
          meta: {
            title: t("仪表盘编辑"),
            noCache: true,
            affix: true
          }
        },
        {
          path: 'workplace',
          component: () => import('@/views/Dashboard/Workplace.vue'),
          name: 'Workplace',
          meta: {
            title: t('router.workplace'),
            noCache: true
          }
        }
      ]
    },
    {
      path: '/workbench',
      component: Layout,
      name: 'Workbench',
      meta: {
        title: '工作台',
        icon: 'ant-design:dashboard-filled',
        alwaysShow: true,
        hidden: true,
      },
      // children: [
      //   {
      //     path: '/:path(.*)',
      //     component: () => import('@/views/Workbench/Index.vue'),
      //     name: 'WorkbenchIndex',
      //     meta: {
      //       title: '工作台',
      //       hidden: true,
      //     }
      //   }
      // ]
    },
    {
      path: '/dataSyncPro',
      component: Layout,
      name: 'DataSyncPro',
      meta: {
        title: '主数据配置平台',
        icon: 'ant-design:dashboard-filled',
        alwaysShow: true,
        hidden: true,
      },
      // children: [
      //   {
      //     path: '/:path(.*)',
      //     component: () => import('@/views/DataSyncPro/Index.vue'),
      //     name: 'dataSyncProIndex',
      //     meta: {
      //       title: '主数据配置平台',
      //       hidden: true,
      //     }
      //   }
      // ]
    },
    {
      path: '/formCustom',
      component: Layout,
      name: 'FormCustom',
      meta: {
        title: '表单配置平台',
        icon: 'ant-design:dashboard-filled',
        hidden: true,
      },
      // children: [
      //   {
      //     path: '/:path(.*)',
      //     component: () => import('@/views/FormCustom/Index.vue'),
      //     name: 'FormCustom',
      //     meta: {
      //       title: '表单配置平台',
      //       hidden: true,
      //     }
      //   },
      // ]
    },
    {
      path: '/external-link',
      component: Layout,
      meta: {},
      name: 'ExternalLink',
      children: [
        {
          path: 'https://element-plus-admin-doc.cn/',
          name: 'DocumentLink',
          meta: {
            title: t('router.document'),
            icon: 'clarity:document-solid'
          }
        }
      ]
    },
    {
      path: '/guide',
      component: Layout,
      name: 'Guide',
      meta: {},
      children: [
        {
          path: 'index',
          component: () => import('@/views/Guide/Guide.vue'),
          name: 'GuideDemo',
          meta: {
            title: t('router.guide'),
            icon: 'cib:telegram-plane'
          }
        }
      ]
    },
    {
      path: '/components',
      component: Layout,
      name: 'ComponentsDemo',
      meta: {
        title: t('router.component'),
        icon: 'bx:bxs-component',
        alwaysShow: true
      },
      children: [
        {
          path: 'form',
          component: getParentLayout(),
          redirect: '/components/form/default-form',
          name: 'Form',
          meta: {
            title: t('router.form'),
            alwaysShow: true
          },
          children: [
            {
              path: 'default-form',
              component: () => import('@/views/Components/Form/DefaultForm.vue'),
              name: 'DefaultForm',
              meta: {
                title: t('router.defaultForm')
              }
            },
            {
              path: 'use-form',
              component: () => import('@/views/Components/Form/UseFormDemo.vue'),
              name: 'UseForm',
              meta: {
                title: 'UseForm'
              }
            }
          ]
        },
        {
          path: 'table',
          component: getParentLayout(),
          redirect: '/components/table/default-table',
          name: 'TableDemo',
          meta: {
            title: t('router.table'),
            alwaysShow: true
          },
          children: [
            {
              path: 'default-table',
              component: () => import('@/views/Components/Table/DefaultTable.vue'),
              name: 'DefaultTable',
              meta: {
                title: t('router.defaultTable')
              }
            },
            {
              path: 'use-table',
              component: () => import('@/views/Components/Table/UseTableDemo.vue'),
              name: 'UseTable',
              meta: {
                title: 'UseTable'
              }
            },
            {
              path: 'tree-table',
              component: () => import('@/views/Components/Table/TreeTable.vue'),
              name: 'TreeTable',
              meta: {
                title: t('router.treeTable')
              }
            },
            {
              path: 'table-image-preview',
              component: () => import('@/views/Components/Table/TableImagePreview.vue'),
              name: 'TableImagePreview',
              meta: {
                title: t('router.PicturePreview')
              }
            },
            {
              path: 'table-video-preview',
              component: () => import('@/views/Components/Table/TableVideoPreview.vue'),
              name: 'TableVideoPreview',
              meta: {
                title: t('router.tableVideoPreview')
              }
            },
            {
              path: 'card-table',
              component: () => import('@/views/Components/Table/CardTable.vue'),
              name: 'CardTable',
              meta: {
                title: t('router.cardTable')
              }
            }
          ]
        },
        {
          path: 'editor-demo',
          component: getParentLayout(),
          redirect: '/components/editor-demo/editor',
          name: 'EditorDemo',
          meta: {
            title: t('router.editor'),
            alwaysShow: true
          },
          children: [
            {
              path: 'editor',
              component: () => import('@/views/Components/Editor/Editor.vue'),
              name: 'Editor',
              meta: {
                title: t('router.richText')
              }
            },
            {
              path: 'json-editor',
              component: () => import('@/views/Components/Editor/JsonEditor.vue'),
              name: 'JsonEditor',
              meta: {
                title: t('router.jsonEditor')
              }
            }
          ]
        },
        {
          path: 'search',
          component: () => import('@/views/Components/Search.vue'),
          name: 'Search',
          meta: {
            title: t('router.search')
          }
        },
        {
          path: 'descriptions',
          component: () => import('@/views/Components/Descriptions.vue'),
          name: 'Descriptions',
          meta: {
            title: t('router.descriptions')
          }
        },
        {
          path: 'image-viewer',
          component: () => import('@/views/Components/ImageViewer.vue'),
          name: 'ImageViewer',
          meta: {
            title: t('router.imageViewer')
          }
        },
        {
          path: 'dialog',
          component: () => import('@/views/Components/Dialog.vue'),
          name: 'Dialog',
          meta: {
            title: t('router.dialog')
          }
        },
        {
          path: 'icon',
          component: () => import('@/views/Components/Icon.vue'),
          name: 'Icon',
          meta: {
            title: t('router.icon')
          }
        },
        {
          path: 'icon-picker',
          component: () => import('@/views/Components/IconPicker.vue'),
          name: 'IconPicker',
          meta: {
            title: t('router.iconPicker')
          }
        },
        {
          path: 'echart',
          component: () => import('@/views/Components/Echart.vue'),
          name: 'Echart',
          meta: {
            title: t('router.echart')
          }
        },
        {
          path: 'count-to',
          component: () => import('@/views/Components/CountTo.vue'),
          name: 'CountTo',
          meta: {
            title: t('router.countTo')
          }
        },
        {
          path: 'qrcode',
          component: () => import('@/views/Components/Qrcode.vue'),
          name: 'Qrcode',
          meta: {
            title: t('router.qrcode')
          }
        },
        {
          path: 'highlight',
          component: () => import('@/views/Components/Highlight.vue'),
          name: 'Highlight',
          meta: {
            title: t('router.highlight')
          }
        },
        {
          path: 'infotip',
          component: () => import('@/views/Components/Infotip.vue'),
          name: 'Infotip',
          meta: {
            title: t('router.infotip')
          }
        },
        {
          path: 'input-password',
          component: () => import('@/views/Components/InputPassword.vue'),
          name: 'InputPassword',
          meta: {
            title: t('router.inputPassword')
          }
        },
        {
          path: 'waterfall',
          component: () => import('@/views/Components/Waterfall.vue'),
          name: 'waterfall',
          meta: {
            title: t('router.waterfall')
          }
        },
        {
          path: 'image-cropping',
          component: () => import('@/views/Components/ImageCropping.vue'),
          name: 'ImageCropping',
          meta: {
            title: t('router.imageCropping')
          }
        },
        {
          path: 'video-player',
          component: () => import('@/views/Components/VideoPlayer.vue'),
          name: 'VideoPlayer',
          meta: {
            title: t('router.videoPlayer')
          }
        },
        {
          path: 'avatars',
          component: () => import('@/views/Components/Avatars.vue'),
          name: 'Avatars',
          meta: {
            title: t('router.avatars')
          }
        },
        {
          path: 'i-agree',
          component: () => import('@/views/Components/IAgree.vue'),
          name: 'IAgree',
          meta: {
            title: t('router.iAgree')
          }
        }
      ]
    },
    {
      path: '/function',
      component: Layout,
      redirect: '/function/multipleTabs',
      name: 'Function',
      meta: {
        title: t('router.function'),
        icon: 'ri:function-fill',
        alwaysShow: true
      },
      children: [
        {
          path: 'multiple-tabs',
          component: () => import('@/views/Function/MultipleTabs.vue'),
          name: 'MultipleTabs',
          meta: {
            title: t('router.multipleTabs')
          }
        },
        {
          path: 'multiple-tabs-demo/:id',
          component: () => import('@/views/Function/MultipleTabsDemo.vue'),
          name: 'MultipleTabsDemo',
          meta: {
            hidden: true,
            title: t('router.details'),
            canTo: true,
            activeMenu: '/function/multiple-tabs'
          }
        },
        {
          path: 'request',
          component: () => import('@/views/Function/Request.vue'),
          name: 'Request',
          meta: {
            title: t('router.request')
          }
        },
        {
          path: 'test',
          component: () => import('@/views/Function/Test.vue'),
          name: 'Test',
          meta: {
            title: t('router.permission'),
            permission: ['add', 'edit', 'delete']
          }
        }
      ]
    },
    {
      path: '/hooks',
      component: Layout,
      redirect: '/hooks/useWatermark',
      name: 'Hooks',
      meta: {
        title: 'hooks',
        icon: 'ic:outline-webhook',
        alwaysShow: true
      },
      children: [
        {
          path: 'useWatermark',
          component: () => import('@/views/hooks/useWatermark.vue'),
          name: 'UseWatermark',
          meta: {
            title: 'useWatermark'
          }
        },
        {
          path: 'useTagsView',
          component: () => import('@/views/hooks/useTagsView.vue'),
          name: 'UseTagsView',
          meta: {
            title: 'useTagsView'
          }
        },
        {
          path: 'useValidator',
          component: () => import('@/views/hooks/useValidator.vue'),
          name: 'UseValidator',
          meta: {
            title: 'useValidator'
          }
        },
        {
          path: 'useCrudSchemas',
          component: () => import('@/views/hooks/useCrudSchemas.vue'),
          name: 'UseCrudSchemas',
          meta: {
            title: 'useCrudSchemas'
          }
        },
        {
          path: 'useClipboard',
          component: () => import('@/views/hooks/useClipboard.vue'),
          name: 'UseClipboard',
          meta: {
            title: 'useClipboard'
          }
        },
        {
          path: 'useNetwork',
          component: () => import('@/views/hooks/useNetwork.vue'),
          name: 'UseNetwork',
          meta: {
            title: 'useNetwork'
          }
        }
      ]
    },
    {
      path: '/level',
      component: Layout,
      redirect: '/level/menu1/menu1-1/menu1-1-1',
      name: 'Level',
      meta: {
        title: t('router.level'),
        icon: 'carbon:skill-level-advanced'
      },
      children: [
        {
          path: 'menu1',
          name: 'Menu1',
          component: getParentLayout(),
          redirect: '/level/menu1/menu1-1/menu1-1-1',
          meta: {
            title: t('router.menu1')
          },
          children: [
            {
              path: 'menu1-1',
              name: 'Menu11',
              component: getParentLayout(),
              redirect: '/level/menu1/menu1-1/menu1-1-1',
              meta: {
                title: t('router.menu11'),
                alwaysShow: true
              },
              children: [
                {
                  path: 'menu1-1-1',
                  name: 'Menu111',
                  component: () => import('@/views/Level/Menu111.vue'),
                  meta: {
                    title: t('router.menu111')
                  }
                }
              ]
            },
            {
              path: 'menu1-2',
              name: 'Menu12',
              component: () => import('@/views/Level/Menu12.vue'),
              meta: {
                title: t('router.menu12')
              }
            }
          ]
        },
        {
          path: 'menu2',
          name: 'Menu2',
          component: () => import('@/views/Level/Menu2.vue'),
          meta: {
            title: t('router.menu2')
          }
        }
      ]
    },
    {
      path: '/example',
      component: Layout,
      redirect: '/example/example-dialog',
      name: 'Example',
      meta: {
        title: t('router.example'),
        icon: 'ep:management',
        alwaysShow: true
      },
      children: [
        {
          path: 'example-dialog',
          component: () => import('@/views/Example/Dialog/ExampleDialog.vue'),
          name: 'ExampleDialog',
          meta: {
            title: t('router.exampleDialog')
          }
        },
        {
          path: 'example-page',
          component: () => import('@/views/Example/Page/ExamplePage.vue'),
          name: 'ExamplePage',
          meta: {
            title: t('router.examplePage')
          }
        },
        {
          path: 'example-add',
          component: () => import('@/views/Example/Page/ExampleAdd.vue'),
          name: 'ExampleAdd',
          meta: {
            title: t('router.exampleAdd'),
            noTagsView: true,
            noCache: true,
            hidden: true,
            canTo: true,
            activeMenu: '/example/example-page'
          }
        },
        {
          path: 'example-edit',
          component: () => import('@/views/Example/Page/ExampleEdit.vue'),
          name: 'ExampleEdit',
          meta: {
            title: t('router.exampleEdit'),
            noTagsView: true,
            noCache: true,
            hidden: true,
            canTo: true,
            activeMenu: '/example/example-page'
          }
        },
        {
          path: 'example-detail',
          component: () => import('@/views/Example/Page/ExampleDetail.vue'),
          name: 'ExampleDetail',
          meta: {
            title: t('router.exampleDetail'),
            noTagsView: true,
            noCache: true,
            hidden: true,
            canTo: true,
            activeMenu: '/example/example-page'
          }
        }
      ]
    },
    {
      path: '/error',
      component: Layout,
      redirect: '/error/404',
      name: 'Error',
      meta: {
        title: t('router.errorPage'),
        icon: 'ci:error',
        alwaysShow: true
      },
      children: [
        {
          path: '404-demo',
          component: () => import('@/views/Error/404.vue'),
          name: '404Demo',
          meta: {
            title: '404'
          }
        },
        {
          path: '403-demo',
          component: () => import('@/views/Error/403.vue'),
          name: '403Demo',
          meta: {
            title: '403'
          }
        },
        {
          path: '500-demo',
          component: () => import('@/views/Error/500.vue'),
          name: '500Demo',
          meta: {
            title: '500'
          }
        }
      ]
    },
    {
      path: '/authorization',
      component: Layout,
      redirect: '/authorization/user',
      name: 'Authorization',
      meta: {
        title: t('router.authorization'),
        icon: 'eos-icons:role-binding',
        alwaysShow: true
      },
      children: [
        {
          path: 'department',
          component: () => import('@/views/Authorization/Department/Department.vue'),
          name: 'Department',
          meta: {
            title: t('router.department')
          }
        },
        {
          path: 'user',
          component: () => import('@/views/Authorization/User/User.vue'),
          name: 'User',
          meta: {
            title: t('router.user')
          }
        },
        {
          path: 'menu',
          component: () => import('@/views/Authorization/Menu/Menu.vue'),
          name: 'Menu',
          meta: {
            title: t('router.menuManagement')
          }
        },
        {
          path: 'role',
          component: () => import('@/views/Authorization/Role/Role.vue'),
          name: 'Role',
          meta: {
            title: t('router.role')
          }
        }
      ]
    }
  ]

  return asyncRouterMap
}


const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
