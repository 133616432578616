import * as NotifyMessageApi from '@/api/system/notify/message'
import tenant from '@/store/tenant'
import { defineStore } from 'pinia'

/**
 * 通知信息
 */
interface NotificationItem {
  // 总通知条数
  count: number;
}


interface NotificationState {
  // 应用未读总条数
  data: Record<string, NotificationItem>;
  // 消息未读条数
  taskCount: number;
  // 待办未读条数
  messageCount: number;
  // 公告未读数
  announceCount: number;
  // 文件中心待确认数量
  fileCount: number;
}


export const useNotification = defineStore('notification', {

  state(): NotificationState {
    return {
      data: {
        myMessage: { count: 0 }
      },
      taskCount: 0,
      messageCount: 0,
      announceCount: 0,
      fileCount: 0,
    }
  },

  getters: {
    count(state) {
      return (key?: string) => {
        if (key) return state[key]
        return 0
      }
    },
    allCount(state) {
      let count = state.taskCount + state.messageCount + state.announceCount + state.fileCount
      for (const key in this.data) {
        count += this.data[key].count
      }
      return count
    }
  },
  actions: {
    // 获取我的消息未读数量
    async getMessageCount() {
      if (tenant.info.portalMyMessageSettingFlag) {
        this.messageCount = await NotifyMessageApi.getUnreadNotifyMessageCount({ isUseToDoMsg: tenant.info.portalUseTodoMsg ? 1 : 0 })
      } else {
        this.messageCount = 0
      }
    },
    // 获取未处理待办条数
    async getToTaskCount() {
      if (tenant.info.portalMyTodoSettingFlag) {
        this.taskCount = await NotifyMessageApi.countTodoTask()
      } else {
        this.taskCount = 0
      }
    },
    // 获取公告未读数量
    async getAnnounceCount() {
      if (tenant.info.portalMyNoticeSettingFlag) {
        this.announceCount = await NotifyMessageApi.getAnnounceUnread()
      } else {
        this.announceCount = 0
      }
    },
    // 获取文件中心待确认数量
    async getFileCount() {
      if (tenant.info.isPortalImportCenter) {
        this.fileCount = await NotifyMessageApi.getFileUnconfirm()
      } else {
        this.fileCount = 0
      }
    },
    // 获取所有消息条数
    async initCount() {
      await this.getToTaskCount()
      await this.getMessageCount()
      await this.getAnnounceCount()
      await this.getFileCount()
    },
    async setMessageCount(messageCount: number) {
      if (tenant.info.portalMyMessageSettingFlag) {
        this.messageCount = messageCount
      }
    },
    async setTaskCount(taskCount: number) {
      if (tenant.info.portalMyTodoSettingFlag) {
        this.taskCount = taskCount
      }
    },
    async setAnnounceCount(announceCount: number) {
      if (tenant.info.portalMyNoticeSettingFlag) {
        this.announceCount = announceCount
      }
    }
  }
})
