import request from '@/axios'
// @ts-ignore
import qs from 'qs'

export interface NotifyMessageVO {
  id: number
  userId: number
  clientType: number
  type: number
  nickName: string
  content: string
  readStatus: boolean
  readTime: Date
  createTime: number
}

// 获得我的站内信分页
export const getMyNotifyMessagePage = async (params: PageParam) => {
  return await request.get({ url: '/system/my-notify/page', params }).then((res) => res.data)
}

// 批量标记已读
export const updateNotifyMessageRead = async (ids) => {
  return await request.put({
    url: '/system/my-notify/update-read?' + qs.stringify({ ids: ids }, { indices: false })
  })
}

// 标记所有站内信为已读
export const updateAllNotifyMessageRead = async (params?) => {
  return await request.put({ url: '/system/my-notify/update-all-read', params })
}

// 获取当前用户的最新站内信列表
export const getUnreadNotifyMessageList = async () => {
  return await request.get({ url: '/system/my-notify/get-unread-list' })
}

// 获取当前用户站内信未读数量
export const getUnreadNotifyMessageCount = async (params?) => {
  return await request.get({ url: '/system/my-notify/get-unread-count', params }).then((res) => res.data)
}

// 获取老版的待办未读数量
export const getUnreaTodoTaskOld = async (params?) => {
  return await request.get({ url: '/system/my-notify/get-unread-todo-count', params }).then((res) => res.data)
}


// 分页获取待办任务
export const getTodoTaskPage = async (params) => {
  return await request.get({ url: '/system/my-todo-task/page', params }).then((res) => res.data)
}

// 设置为已完成待办任务
export const updateDoneTodoTask = async (data?) => {
  return await request.put({ url: `/system/my-todo-task/update-done`, data })
}

// 获取所有待办任务
export const getTodoTaskList = async (params) => {
  return await request.get({ url: '/system/my-todo-task/list', params }).then((res) => res.data)
}

// 获取所有待办任务
export const getTodoTaskListComponent = async (params) => {
  return await request.get({ url: '/f-flow/task/my-todo-task-list', params }).then((res) => res.data)
}

// 更新待办任务 已读
export const updateTodoTaskRead = async (params) => {
  return await request.put({ url: '/f-flow/task/read-task', params })
}

// 获取当前用户待办任务列表
export const countTodoTask = async (params?) => {
  return await request.get({ url: `/f-flow/task/my-todo-task-ctn`, params }).then((res) => res.data)
}

export const getSourceList = async () => {
  return await request.get({ url: '/system/my-todo-task/getSourceList' }).then((res) => res.data)
}

export const getTaskTypeList = async () => {
  return await request.get({ url: '/system/my-todo-task/getTaskTypeList' }).then((res) => res.data)
}

/** 公告 */
// 获取列表
export const getAnnounceList = async (data) => {
  return await request.post({ url: '/sys/notice/page', data }).then((res) => res.data)
}

//详情
export const getAnnounceDetail = async (params) => {
  return await request.get({ url: '/sys/notice/get', params }).then((res) => res.data)
}

//标记单个已读
export const updateAnnounceRead = async (params: { id: number }) => {
  return await request.get({ url: '/sys/notice/markRead', params }).then((res) => res.data)
}

//全部已读
export const updateAnnounceReadAll = async () => {
  return await request.get({ url: '/sys/notice/markAllRead' }).then((res) => res.data)
}

//未读红点
export const getAnnounceUnread = async () => {
  return await request.get({ url: '/sys/notice/unreadGet' }).then((res) => res.data)
}
/** 公告 end */

export const getCountInfo = async () => {
  return await request.get({ url: `/system/my-todo-task/count` }).then((res) => res.data)
}
//文件导入 未确认数量
export const getFileUnconfirm = async () => {
  return await request.get({ url: '/f-form/import-file/getImportPendingCount' }).then((res) => res.data)
}

//文件未确认数量 包含导入和导出
export const getFileCenterUnconfirmV2 = async (params) => {
  return await request.get({ url: '/f-form/import-file/unread-ctn', params }).then((res) => res.data)
}
