import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { ElMessage } from 'element-plus'
import qs from 'qs'
import { SUCCESS_CODE, SUCCESS_CODE_REPORT, TRANSFORM_REQUEST_DATA } from '@/constants'
import { getTenantId, getTenantName, useUserStoreWithOut } from '@/store/modules/user'
import { objToFormData } from '@/utils'
import service from './service'

// 是否正在刷新中
let isRefreshToken = false
// 请求队列
let requestList: any[] = []

// 刷新token
const refreshToken = async (token) => {
  return await service.request({
    method: "post",
    url: `/system/sso-auth/refresh-token?refreshToken=` + token,
    headers:{ 
      ['tenant-id']: getTenantId() ?? '',
      ['tenant-name']: getTenantName() ?? '',
    }
  })
}

const defaultRequestInterceptors = (config: InternalAxiosRequestConfig) => {
  if (
    config.method === 'post' &&
    config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    config.data = qs.stringify(config.data)
  } else if (
    TRANSFORM_REQUEST_DATA &&
    config.method === 'post' &&
    config.headers['Content-Type'] === 'multipart/form-data'
  ) {
    config.data = objToFormData(config.data)
  }
  if (config.method === 'get' && config.params) {
    let url = config.url as string
    url += '?'
    const keys = Object.keys(config.params)
    for (const key of keys) {
      if (config.params[key] !== void 0 && config.params[key] !== null) {
        url += `${key}=${encodeURIComponent(config.params[key])}&`
      }
    }
    url = url.substring(0, url.length - 1)
    config.params = {}
    config.url = url
  }
  return config
}

const defaultResponseInterceptors = (response: AxiosResponse) => {
  const config = response.config
  if (config?.responseType === 'blob') {
    // 如果是文件流，直接过
    return response
  } else if (response.data.code === SUCCESS_CODE || response.data.errorCode === SUCCESS_CODE_REPORT) { //兼容报表项目的code
    return response.data
  } else if(response.status == 401 || response?.data?.code === 401) {
    // 兼容之前401 逻辑
    const userStore = useUserStoreWithOut()
    // 如果未认证，并且未进行刷新令牌，说明可能是访问令牌过期了
    if (!isRefreshToken) {
      isRefreshToken = true
      // 1. 如果获取不到刷新令牌，则只能执行登出操作
      if (!userStore.getRefreshToken) {
        return userStore.logout()
      }
      // 2. 进行刷新访问令牌
      return refreshToken(userStore.getRefreshToken).then((res: any) => {
          userStore.setToken(res.data.data)
          config.headers!.Authorization = 'Bearer ' + userStore.getAccessToken()
          // 2.1 刷新成功，则回放队列的请求 + 当前请求
          requestList.forEach((cb: any) => {
            cb()
          })
          requestList = []
          return service.request(config)
      }).catch(() => {
        userStore.logout();
      })
    }else {
        // 添加到队列，等待刷新获取到新的令牌
        return new Promise((resolve) => {
        requestList.push(() => {
          config.headers!.Authorization = 'Bearer ' + userStore.getAccessToken() // 让每个请求携带自定义token 请根据实际情况自行修改
          resolve(service.request(config))
        })
      })
    }
  }else {
    if (response?.data?.message || response?.data?.msg){
      ElMessage.error(response?.data?.message || response?.data?.msg)
    }
  }
}

export { defaultResponseInterceptors, defaultRequestInterceptors }
